body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  margin: 0 20%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.index-header {
  text-align: center;
  font-size: 10em;
}

.key-generation {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  margin: 0 8px;
}

#gematria-key {
  margin: 8px 0;
  padding: 8px;
}

#gematria-key-generation-palette {
  border: 1px solid black;
  border-radius: 2px;
  width: auto;
  height: 100%;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 16px;
  margin: 8px;
  width: 100%;
}

.footer * {
  text-align: center;
  min-height: 32px;
}

.footer .footer-page-name {
  color: red;
}
